type LoadingSpinnerProps = {
  size?: string;
};

export function LoadingSpinner({ size = "24" }: LoadingSpinnerProps) {
  return (
    <div
      className={`w-[${size}px] h-[${size}px] border-4 border-b-gray-500 rounded-full animate-spin	`}
    />
  );
}
